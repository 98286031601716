$purple: #845EC2;
$pink: #EA4C89;
$purple-2: #D644FC;
$pink-2: #FC5193;

@keyframes circle-animation {
    0% {
        background-color: $purple;
        transform: scale(1.0);
    }
    33% {
        background-color: $pink;
    }
    66% {
        background-color: $pink-2;
    }
    100% {
        background-color: $purple-2;
        transform: scale(1.4);
    }
}
.contact {
    background-color: #FBEAFF;
    padding-bottom: 24px;
    padding-top: 16px;
    h2 {
        color: $purple;
        text-align: center;

    }
    img {
        width: 80px;
    }
    .myContact {
        display: flex;
        justify-content: center;
        .circle {
            border-radius: 100%;
            background-color: $purple;
            padding: 12px;
            margin-right: 14px;
            a {
                display: block;
            }
            &:hover {
                animation: circle-animation 1s ease infinite alternate;
            }
        }
        
    }

    
}

@media screen and (min-width: 768px) {
    .contact {

        img {
            width: 47px;
        }
       
        h2 {
            margin-left: 12px;
        }
        
        
    }
    
}


